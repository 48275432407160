import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from 'react-router-dom';
import { setHistory } from 'router';
import './App.css';

const IMPORTERS: {
  [key in
    | 'LandingPage'
    | 'LoginPage'
    | 'FormsPage'
    | 'FormPage']: () => Promise<any>;
} = {
  LandingPage: () => import('LandingPage/LandingPage'),
  LoginPage: () => import('LoginPage/LoginPage'),
  FormsPage: () => import('FormsPage/FormsPage'),
  FormPage: () => import('FormPage/FormPage')
};

const LazyRoute = React.memo(function ({
  path,
  exact,
  component
}: {
  path: string;
  exact?: boolean;
  component: keyof typeof IMPORTERS;
}) {
  const LazyComponent = React.useMemo(() => React.lazy(IMPORTERS[component]), [
    IMPORTERS[component]
  ]);
  return (
    <>
      <Route
        {...{ exact, path }}
        component={({ match }: RouteComponentProps<{ id: string }>) => (
          <Suspense fallback={() => <></>}>
            <LazyComponent match={match} />
          </Suspense>
        )}
      />
    </>
  );
});

function EmptyStart() {
  return (
    <div>
      <div>
        <a href="/forms">Forms</a>
      </div>
      <div>
        <a href="/login">Login</a>
      </div>
    </div>
  );
}

const GetHistory = withRouter(function (
  props: RouteComponentProps<{ form_id: string }>
) {
  setHistory(props.history);
  return <></>;
});

function App() {
  return (
    <Router>
      <GetHistory />
      <Switch>
        <LazyRoute exact path="/" component="LandingPage" />
        <LazyRoute exact path="/login" component="LoginPage" />
        <LazyRoute exact path="/forms" component="FormsPage" />
        <LazyRoute exact path="/form/:form_id" component="FormPage" />
      </Switch>
    </Router>
  );
}

export default App;
