import { RouteComponentProps } from 'react-router-dom';

const container: { history: RouteComponentProps['history'] } = {
  history: undefined
};

export function setHistory(history: RouteComponentProps['history']) {
  if (!container.history) {
    container.history = history;
    console.log('History: ', container.history);
  }
}

export function getHistory() {
  return container.history;
}

export function pushHistory(to: string, state?: any) {
  return container.history.push(to, state);
}
